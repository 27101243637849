<template>
  <div class="address-selector" style="display: block !important">
    <b-form-group :label="label" class="mb-0"> </b-form-group>
    <b-form-group>
      <p
        v-if="
          (!onFocus && isSubmit) ||
          (inputLocation && !isSelected && !multiPackage.isChangeMaid && isTyping)
        "
        class="text-danger"
      >
        {{ $t('form_label.please_select_your_location') }}
      </p>

      <div class="address-location">
        <b-input-group>
          <b-form-textarea
            :style="inputLocation && inputLocation.length > 15 ? { maxHeight: '89px' } : { height: '39px' } 
            "
            id="beforemodal"
            @focus="onFocus"
            @blur="offFocus"
            v-model.trim="inputLocation"
            @keyup="onInputLocationChanged"
            :disabled="disabled"
            autocomplete="off"
            max-rows="3"
            :placeholder="$t('form_label.your_location')"
            class="hide-scrollbar"
          >
          </b-form-textarea>
          <button  v-if="inputLocation" @click="clearInputModalBooking" class="clear-input-area-button">
            <b-img :src="require('../assets/images/icons/close.png')" width="12"></b-img>
          </button>
          <b-input-group-text
            v-if="DisabledLocation"
            :style="inputDisabledLocation"
            @click="getCurrentLocation"
            class="position-higher"
          >
            <b-img :src="require('../assets/images/icons/locator.png')" width="16"></b-img>
          </b-input-group-text>
          <b-input-group-text v-else :style="inputStyle" @click="openModal">
            <b-img :src="require('../assets/images/icons/locator.png')" width="16"></b-img>
          </b-input-group-text>
        </b-input-group>
        <div v-if="(!additional_details || additional_details === '') && add_details">
          <small style="word-break: break-word"
            >{{ $t('form_label.additional_details') }}: {{ add_details }}
          </small>
        </div>

        <div
          v-if="
            additional_details !== undefined &&
            additional_details !== null &&
            additional_details !== ''
          "
        >
          <small style="word-break: break-word"
            >{{ $t('form_label.additional_details') }}: {{ additional_details }}
          </small>
        </div>
      </div>

      <transition name="fade" class="modal">
        <div
          id="address-selector1"
          ref="address_selector1"
          class="address-selector-container"
          v-if="isFocus"
        >
          <div v-if="isFetching && inputLocation" class="mt-3">
            <LoadingDots />
          </div>
          <div v-if="searchResult.length > 0">
            <!-- <p class="mb-0 ml-3 mt-3 mb-2">{{ $t('form_label.search_result') }}</p> -->
            <div
              @click="selectAddress(place)"
              v-for="(place, idx) in searchResult"
              :key="`s-${idx}`"
            >
              <address-detail
                :address="place"
                :ref="`s_${idx}`"
                class="option-custom"
              ></address-detail>
            </div>
          </div>
          <div
            v-if="searchResult.length === 0 && inputLocation && !isFetching"
            class="py-3 text-center"
          >
            <span>{{ $t('form_label.no_result') }}</span>
          </div>

          <!-- Default Location -->
          <p class="mb-0 ml-3 mt-3 mb-2">{{ $t('form_label.default_location') }}</p>
          <div
            @click="selectAddress(place)"
            v-for="(place, idx) in defaultLocation"
            :key="`d-${idx}`"
          >
            <address-detail :address="place" class="option-custom"></address-detail>
          </div>
          <div v-if="defaultLocation.length === 0" class="py-3 text-center">
            <span>{{ $t('form_label.no_result') }}</span>
          </div>

          <!-- History Location -->
          <p class="mb-0 ml-3 mt-3 mb-2">{{ $t('form_label.history') }}</p>
          <div
            @click="selectAddress(addr)"
            v-for="(addr, index) in locationHistory"
            :key="index * 2"
          >
            <address-detail class="option-custom" :address="addr"></address-detail>
          </div>
          <div v-if="locationHistory.length === 0" class="text-center py-4">
            <span>{{ $t('form_label.no_result') }}</span>
          </div>
        </div>
      </transition>
    </b-form-group>

    <!-- Modal Maping && Current Location -->
    <BaseModal :id="`gmap-picker-modal-${value.number}`" size="lg" @shown="onModalShown">
      <div>
        <div class="text-danger pb-2" v-if="!isSelectedModal && inputLocationModal != ''">
          {{ $t('form_label.please_select_your_location') }}
        </div>
        <b-input-group>
          <b-form-textarea
            id="locationinput"
            :style="inputLocationModal && inputLocationModal.length > 15 ? { maxHeight: '89px' } : { height: '39px' }"
            @focus="onModal"
            @blur="offModal"
            :placeholder="$t('form_label.your_location')"
            v-model.trim="inputLocationModal"
            @keyup="onInputLocationChangedModal"
            :disabled="disabled"
            autocomplete="off"
            max-rows="3"
            class="hide-scrollbar"
          >
          </b-form-textarea>
          <button  v-if="inputLocationModal" @click="clearInputModal" class="clear-input-area-button-1">
            <b-img :src="require('../assets/images/icons/close.png')" width="12"></b-img>
          </button>
          <div v-if="isFetching2 && !openSelector" class="loading-dots">
            <LoadingDots class="mt-1" />
          </div>
        </b-input-group>
        <transition name="fade" class="modal">
          <div
            id="address-selector2"
            ref="address_selector2"
            class="address-selector-container"
            v-if="openSelector"
          >
            <div v-if="isFetching2 && inputLocationModal" class="mt-3">
              <LoadingDots />
            </div>
            <div v-if="searchResult.length > 0">
              <!-- <p class="mb-0 ml-3 mt-3 mb-2">{{ $t('form_label.search_result') }}</p> -->
              <div
                @click="selectAddress(place, true)"
                v-for="(place, idx) in searchResult"
                :key="`s-${idx}`"
              >
                <address-detail
                  :address="place"
                  :ref="`s_${idx}`"
                  class="option-custom"
                ></address-detail>
              </div>
            </div>
            <div
              v-if="searchResult.length === 0 && inputLocationModal && !isFetching2"
              class="py-3 text-center"
            >
              <span>{{ $t('form_label.no_result') }}</span>
            </div>

            <!-- Default Location -->
            <p class="mb-0 ml-3 mt-3 mb-2">{{ $t('form_label.default_location') }}</p>
            <div
              @click="selectAddress(place, true)"
              v-for="(place, idx) in defaultLocation"
              :key="`d-${idx}`"
            >
              <address-detail :address="place" class="option-custom"></address-detail>
            </div>
            <div v-if="defaultLocation.length === 0" class="py-3 text-center">
              <span>{{ $t('form_label.no_result') }}</span>
            </div>

            <!-- History Location -->
            <p class="mb-0 ml-3 mt-3 mb-2">{{ $t('form_label.history') }}</p>
            <div
              @click="selectAddress(addr, true)"
              v-for="(addr, index) in locationHistory"
              :key="index * 2"
            >
              <address-detail class="option-custom" :address="addr"></address-detail>
            </div>
            <div v-if="locationHistory.length === 0" class="text-center py-4">
              <span>{{ $t('form_label.no_result') }}</span>
            </div>
          </div>
        </transition>
        <b-input-group class="mt-2">
          <b-input-group-text
            v-if="disabledLocation"
            class="location-current w-100"
            :style="inputDisabledLocation"
            @click="getCurrentLocation"
          >
            {{ $t('form_label.your_current_location') }} &nbsp;
            <b-img :src="require('../assets/images/icons/location-icon.svg')"></b-img>
          </b-input-group-text>
          <b-input-group-text v-else class="location-current w-100" :style="inputStyle" @click="getCurrentLocation">
            {{ $t('form_label.your_current_location') }} &nbsp;
            <b-img :src="require('../assets/images/icons/location-icon.svg')"></b-img>
          </b-input-group-text>
          <div v-if="isFetching2 && !openSelector" class="loading-dots">
          </div>
        </b-input-group>
      </div>
      <div style="position: relative">
      <div 
        v-if="isFetching2 || isDefaultLocation" 
        style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 9999; background-color: rgba(255, 255, 255, 0.2)"
      ></div>
      <div id="map" ref="gmaps" style="height: 400px" class="my-2"></div>
      </div>

      <b-row>
        <!-- <b-col cols="12">
          <b-form-group :label="$t('form_label.address_info')">
            <label class="mb-0" v-if="!isFetching"> {{ form.main_text }} </label>
            <LoadingDots v-else />
          </b-form-group>
        </b-col> -->
        <b-col cols="12">
          <b-form-group
            class="mb-0"
            v-if="!(isUnnamedRoad || isPlusCode)"
            :label="$t('form_label.additional_details')"
          >
            <b-form-input :maxlength="max" v-model="form.additional_details"></b-form-input>
          </b-form-group>

          <b-form-group
            id="additional_required"
            v-if="isUnnamedRoad || isPlusCode"
            :label="$t('form_label.required_details')"
          >
            <b-form-input
              :maxlength="max"
              @keyup="isTypeAdd(form.additional_details)"
              v-model="form.additional_details"
            ></b-form-input>
          </b-form-group>

          <div class="mb-2">
            <small class="text-danger">
              {{
                (isPlusCode || isUnnamedRoad) && !isOutOfArea
                  ? $t('form_label.map_not_recognize')
                  : ` `
              }}
            </small>
          </div>
        </b-col>

        <b-col cols="12">
          <b-button
            variant="primary"
            :disabled="
              !inputLocationModal ||
              isFetching ||
              isFetching2 ||
              isOutOfArea ||
              ((isPlusCode || isUnnamedRoad) && !isAdditionalDetail) ||
              !isSelectedModal ||
              isDefaultLocation
            "
            block
            @click="onSubmit"
          >
            <div v-if="isFetching2">
              <LoadingBtn></LoadingBtn>
            </div>
            <div v-else>
              {{ isOutOfArea ? mapsPickerError : $t('button.confirm') }}
            </div>
          </b-button>

          <small class="text-danger">
            {{ isOutOfArea && isSelectedModal ? $t('form_label.we_are_still_in_beta_stage') : ` ` }}
          </small>
        </b-col>
      </b-row>
    </BaseModal>
    <BaseModal id="alert-address-error" size="md">
      <div class="">
        <p v-html="$t('error.address_error')"></p>
      </div>
    </BaseModal>
  </div>
</template>
<script>
import axios from 'axios'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import AddressDetail from './Address'
import ApiClient from '../services'
import LoadingDots from '../components/LoadingDots'
import LoadingBtn from '../components/LoadingBtn'
import MapItemLoader from '../components/MapItemLoader'
import BaseModal from '../components/Modal/BaseModal'
import { isPlaceInAvailableArea2 } from '../helpers/maps'
import { findLatLongIfNotExists } from '../utils'
import Constant, { STATUS } from '../config/booking'
import { v4 as uuidv4 } from 'uuid'

const UserStore = createNamespacedHelpers('user')
const BookingStore = createNamespacedHelpers('booking')

let maps,
  marker,
  isDrag,
  disabledLocation = false

@Component({
  components: { AddressDetail, LoadingDots, LoadingBtn, BaseModal, MapItemLoader },
  computed: {
    ...UserStore.mapState(['user_locations', 'location_history']),
    ...BookingStore.mapState(['multiPackage', 'bookingReview', 'oneTime', 'book_raw']),
  },
  props: ['disabled', 'add_details', 'prev', 'value'],
  watch: {
    prev: {
      immediate: true,
      handler(val, oldVal) {
        if (val.name === 'BookingReview') {
          console.log('eeee : BookingReview')
          this.previousLocation()
        }
        if (val.name === 'SelectMaid') {
          // console.log('eeee : maid')
          // console.log('eeee : page', this.$route.name)
          // if (this.isMyBookingOneTime && !this.isSubmit) {
          //   console.log('eeee : ot update')
          //   this.BookOneTimeLocation()
          // }
          if (this.$route.name === 'BookingOneTime') {
            // console.log('eeee : otot yeah')

            // this.center = {
            //   lat: this.oneTime.location.latitude,
            //   lng: this.oneTIme.location.longitude,
            // }
            let location = this.oneTime.location
            if (location.main_text.includes(location.description)) {
              this.inputLocation = location.main_text
            } else {
              this.inputLocation = location.main_text + ' ' + location.description
            }
            this.form.main_text = this.oneTime.location.main_text
            this.form.latitude = this.oneTime.location.latitude
            this.form.longitude = this.oneTime.location.longitude
            this.form.place_id = this.oneTime.location.place_id
            this.form.additional_details = this.oneTime.location.additional_details
            this.isSubmit = false

            // console.log('eeee : yes', this.form)
            // this.BookOneTimeLocation()
          }
          if (this.$route.name === 'BookingMultiPackage') {
            console.log('eeee : mp')
            console.log('eeee : mp', this.form)
            let location = this.multiPackage.location
            if (location.main_text.includes(location.description)) {
              this.inputLocation = location.main_text
            } else {
              this.inputLocation = location.main_text + ' ' + location.description
            }
            this.form.main_text = this.multiPackage.location.main_text
            this.form.latitude = this.multiPackage.location.latitude
            this.form.longitude = this.multiPackage.location.longitude
            this.form.place_id = this.multiPackage.location.place_id
            this.form.additional_details = this.multiPackage.location.additional_details
            this.isSubmit = false
          }
          if (this.isMyBookingMultiPackage && !this.isSubmit) {
            console.log('eeee : mp create maid select')
            this.BookMPLocation()
          }
        }
        //  console.log("eeee : watch prev",val)
      },
    },
    oneTime(newVal, oldVal) {
      if (this.isMyBookingOneTime && !this.isFromBookingReview && !this.oldAddDetails) {
        console.log('eeee : ot update watcher')
        // this.isSubmit = false
        this.BookOneTimeLocation()
      }
    },
    multiPackage(newVal, oldVal) {
      if (this.isMyBookingMultiPackage && !this.isFromBookingReview && !this.oldAddDetails) {
        console.log('eeee : mp update watcher')
        // this.isSubmit = false
        this.BookMPLocation()
      }
    },
  },
})
export default class AddressSelect extends Vue {
  availableArea = []
  isBlank = false
  isTyping = false
  isFocus = false
  openSelector = false
  isFetching = false
  isFetching2 = false
  isSubmit = false
  inputLocation = null
  inputLocationModal = null
  isLocationInput = false
  isSelectedNewAdd = false
  centerLocation = null
  recentInputLocation = null
  oldAddDetails = null
  searchResult = []
  userCurrentLocation = null
  isAdditionalDetail = false
  currentLocation = null
  timeoutInstance = null
  isPreventQuery = true
  center = { lat: -79.7555, lng:  86.7420}
  centerDefault = { lat: 13.7506297, lng: 100.5313122 }
  otherCenterDefault = [
    { lat: 13.750654544845148, lng: 100.53137294422847 },
    { lat: 13.7507, lng: 100.5314 },
    { lat: 13.7506, lng: 100.531 },
    { lat: 13.750629700000001, lng: 100.53131220000002 },
    { lat: 13.7506297, lng: 100.5313122 }
  ]
  mapsTimeout = null
  backUpTime = null
  text_main_location = ''
  mapsPickerError = ''
  disabledLocation = false
  max = 100
  isMounted = false
  bookingReviewDetails = true
  isSelectedModal = true
  modalFirstClick = true
  c = 0
  debounceAutocomplete = null
  areaLocation = null
  temp_id = null

  form = {
    main_text: '',
    description: '',
    secondary_text: '',
    latitude: '',
    longitude: '',
    additional_details: null,
  }

  get isMyAccount() {
    return this.$route.name === 'MyAccount'
  }

  get isMyBookingOneTime() {
    return this.$route.name == 'MyBookingOneTime'
  }

  get isMyBookingMultiPackage() {
    return this.$route.name == 'MyBookingMultiPackage'
  }
  get isSelectMaid() {
    return this.prev.name === 'SelectMaid'
  }

  //Searching results
  get defaultLocation() {
    return this.user_locations ? this.user_locations : []
  }
  get isFromBookingReview() {
    return this.prev && this.prev.name === 'BookingReview'
  }
  get isFromSelectMaid() {
    return this.prev.name === 'SelectMaid'
  }

  get additional_details() {
    console.log('eeee : addiwa', this.location_additional_details)
    let additionalDetails = this.form.additional_details

    if (this.isFromBookingReview) {
      additionalDetails = this.bookingReview.location.additional_details
      this.bookingReviewDetails = false
      console.log('BOOKING REVIEW👹 additional details address select ===>', additionalDetails)
      if (additionalDetails) {
        if (additionalDetails != this.oldAddDetails) additionalDetails = this.oldAddDetails
        return `${additionalDetails}`
      } else return null
    }
    if (this.isSubmit) {
      additionalDetails = this.form.additional_details
      console.log('FORM 👹 ตอนที่กด submit ===>', additionalDetails)
      if (additionalDetails) {
        console.log('มี additional details มั้ย ถ้ามี ADDR DETAILS ===>', additionalDetails)
        additionalDetails = this.oldAddDetails
        return `${additionalDetails}`
      }
    }
    if (!this.isSubmit) {
      console.log('eeee : old', this.oldAddDetails, additionalDetails)
      if (this.oldAddDetails) {
        return this.oldAddDetails
      } else if (this.$route.name === 'BookingOneTime') {
        additionalDetails = this.oneTime.location.additional_details
      } else if (this.$route.name === 'BookingMultiPackage') {
        additionalDetails = this.multiPackage.location.additional_details
      }

      // additionalDetails = this.form.additional_details
      // console.log('eeee : oldw', additionalDetails)
      return additionalDetails
    }

    return additionalDetails
  }

  isTypeAdd(value) {
    let val = value

    let isNUll = val === null || val === '' || val === ' ' || val === undefined

    if (isNUll) {
      this.isAdditionalDetail = false
    } else {
      this.isAdditionalDetail = true
      this.isSelectedNewAdd = false
    }
    console.log('value this.isAdditionalDetail', val, this.isAdditionalDetail)
    // alert(value)
  }

  //warning text below additional details
  get isOutOfArea() {
    return Boolean(this.mapsPickerError)
  }

  get isUnfilled() {
    if (this.isPlusCode || this.isUnnamedRoad) {
      return true
    }
    return false
  }

  get isPlusCode() {
    let address = this.inputLocationModal
    if (address) {
      return address.toLowerCase().includes('+')
    }
  }

  get isUnnamedRoad() {
    let address = this.inputLocationModal
    if (address) {
      return address.toLowerCase().includes('unnamed')
    }
  }

  get prevPage() {
    let pre = this.prev
    return pre
  }

  get isSelected() {
    let answer = true
    let isInputEqualValue =
      this.value.main_text === this.inputLocation ||
      this.value.main_text + ' ' + this.value.secondary_text === this.inputLocation ||
      this.value.main_text + ' ' + this.value.description === this.inputLocation
    let isValue = this.value.main_text === ''
    console.log('this.value.main_text====>', this.value.main_text)

    if ((!this.inputLocation && !isValue) || isInputEqualValue) {
      answer = true
    } else {
      answer = false
    }
    return answer
  }

  get locationHistory() {
    return this.location_history
      .filter((v, i, a) => a.findIndex(v2 => v2.location_name === v.location_name) === i)
      .map(l => ({
        main_text: l.location_name,
        description: l.location_secondary,
        secondary_text: l.location_secondary,
        latitude: l.location_latitude,
        longitude: l.location_longitude,
        place_id: l.location_place_id,
      }))
  }
  

  get isDefaultLocation() {
    return this.center.lat === -79.7555 && this.center.lng === 86.7420
  }

  get isCenterDefault() {
    return (
      ( Math.round(this.center.lat * 1000000) / 1000000 ===
        Math.round(this.centerDefault.lat * 1000000) / 1000000 &&
      Math.round(this.center.lng * 1000000) / 1000000 ===
        Math.round(this.centerDefault.lng * 1000000) / 1000000 ) ||
      ( this.center.lat == this.centerDefault.lat && this.center.lng == this.centerDefault.lng) ||
      this.checkOtherCenterDefault(this.center.lat, this.center.lng)
    )
  }

  checkOtherCenterDefault(lat, lng) {
    let isCenter = false
    this.otherCenterDefault.forEach(center => {
      if (
        ( Math.round(lat * 100000) / 100000 === Math.round(center.lat * 100000) / 100000 &&
        Math.round(lng * 100000) / 100000 === Math.round(center.lng * 100000) / 100000 ) ||
        ( lat == center.lat && lng == center.lng)
      ) {
        isCenter = true
      }
    })
    return isCenter
  }

  async mounted() {
    console.log('CONDITION TYPEOF', typeof localStorage.isFromBookingReview)

    // console.log('eeee : prevPage', this.prevPage)
    // if (
    //   (localStorage.isFromBookingReview == 'null' ||
    //     localStorage.isFromBookingReview == null ||
    //     localStorage.isFromBookingReview == undefined) &&
    //   !this.isApproved
    // ) {
    //   if (this.isMyBookingOneTime) {
    //     console.log('CONDITION 3')
    //     this.BookOneTimeLocation()
    //     // this.inputLocation = this.form.main_text
    //     this.center = {
    //       lat: this.oneTime.location.latitude,
    //       lng: this.oneTime.location.longitude,
    //     }
    //     await this.onInputLocationChanged()
    //   }
    //   if (localStorage.isFromSelectMaid === 'select-maid') {
    //     console.log('CONDITION 2')
    //     // this.inputLocation = this.form.main_text
    //     this.center = {
    //       lat: this.oneTime.location.latitude,
    //       lng: this.oneTime.location.longitude,
    //     }
    //     await this.onInputLocationChanged()
    //   }
    //   console.log('CONDITION 1 MP')
    //   if (this.isMyBookingMultiPackage) {
    //     this.BookMPLocation()
    //     console.log('heellll')
    //     this.center = {
    //       lat: this.multiPackage.location.latitude,
    //       lng: this.multiPackage.location.longitude,
    //     }
    //     await this.onInputLocationChanged()
    //   }
    // }
    // if (localStorage.isFromBookingReview === 'booking-review') {
    //   console.log('CONDITION 4')
    //   this.form = { ...this.bookingReview.location }
    //   if (
    //     this.bookingReview.location.additional_details &&
    //     this.bookingReview.location.additional_details !== ''
    //   ) {
    //     this.form.additional_details = this.bookingReview.location.additional_details
    //     this.oldAddDetails = this.form.additional_details
    //   }
    //   this.inputLocation = this.bookingReview.location.main_text
    //   this.center = {
    //     lat: this.bookingReview.location.latitude,
    //     lng: this.bookingReview.location.longitude,
    //   }
    //   this.previousLocation()
    //   this.onInputLocationChanged()
    // }
    // console.log('this.inputLocation', this.inputLocation)
    // setTimeout(() => {}, 1000)
    this.isMounted = true
    this.temp_id = uuidv4()
  }

  BookOneTimeLocation() {
    // if (this.inputLocation != null) {
    //   this.form.main_text = this.inputLocation
    // }

    console.log('eeee : oneTime', this.oneTime)
    const location = this.oneTime.location
    this.center = {
      lat: location.latitude,
      lng: location.longitude,
    }
    this.form.latitude = location.latitude
    this.form.longitude = location.longitude
    this.form.place_id = location.place_id
    this.form.additional_details = this.oneTime.location_additional_details
    if (location.main_text.includes(location.description)) {
        this.inputLocation = location.main_text
    } else {
        this.inputLocation = location.main_text + ' ' + location.description
    }
    // this.initMaps()
  }

  BookMPLocation() {
    // if (this.inputLocation != null) {
    //   this.form.main_text = this.inputLocation
    // }
    const location = this.multiPackage.location
    this.center = {
      lat: location.latitude,
      lng: location.longitude,
    }
    this.form.latitude = location.latitude
    this.form.longitude = location.longitude
    this.form.place_id = location.place_id
    console.log('eeee : mp : location ', location)
    this.form.additional_details = this.multiPackage.location_additional_details
    if (location.main_text.includes(location.description)) {
        this.inputLocation = location.main_text
    } else {
        this.inputLocation = location.main_text + ' ' + location.description
    }
    // this.initMaps()
  }

  previousLocation() {
    if(this.$route.name == "BookingOneTime" && this.bookingReview.type != 'one-time'){
      return
    }
    if(this.$route.name == "BookingMultiPackage" && this.bookingReview.type != 'multi-package'){
      return
    }
    const location = this.bookingReview.location
    console.log('eeee : bookingReview.location', this.bookingReview.location)
    if (
      this.bookingReview.location.additional_details &&
      this.bookingReview.location.additional_details !== ''
    ) {
      this.form.additional_details = this.bookingReview.location.additional_details
      this.oldAddDetails = this.form.additional_details
    }
    this.center = {
      lat: location.latitude,
      lng: location.longitude,
    }
    this.form.latitude = location.latitude
    this.form.longitude = location.longitude
    this.form.place_id = location.place_id
    if (location.main_text.includes(location.description)) {
        this.inputLocation = location.main_text
    } else {
        this.inputLocation = location.main_text + ' ' + location.description
    }
    // localStorage.isFromBookingReview = null
    // this.initMaps()
  }

  //get current position of yourself using google maps
  // async getUserCurrentLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       async (position) => {
  //         console.log('Current', position)
  //         this.position = position
  //         this.center = {
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         }
  //         await this.getAddress(position.coords.latitude, position.coords.longitude)
  //         const newLatLng = new google.maps.LatLng(this.center)
  //         if (!maps) {
  //           this.initMaps()
  //         }
  //         marker && (marker.position = this.center)
  //         maps && maps.panTo(newLatLng)
  //         await this.getPlaceByLatLng()
  //       },
  //       (error) => {
  //         console.log('error', error.message)
  //         disabledLocation = true
  //         this.disabledLocation = disabledLocation
  //       }
  //     )
  //     // this.disabledLocation = disabledLocation
  //   } else {
  //     console.log("Your browser doesn't support geolocation API")
  //   }
  // }
  get DisabledLocation() {
    let disablelct = this.disabledLocation
    console.log('disablelct', disablelct)
    return disablelct
  }

  //Map Modal Available Area
  async fetchAvailableArea() {
    if (this.availableArea.length > 0) return
    try {
      // const result = await ApiClient.getDataArea()
      const result = await ApiClient.getAreaRadius()

      this.availableArea = result.data
      console.log(this.availableArea)
    } catch (e) {
      console.log(e.response)
    }
  }

  //Select Address in searching Results
  async selectAddress(address, modal = false) {
    address = await findLatLongIfNotExists(address)
    const start_with = ['ตำบล', 'อำเภอ', 'จังหวัด', 'แขวง', 'เขต' , 'Amphoe', 'Tambon', 'Khet', 'Khwaeng', 'District', 'Province']
    if (start_with.some((word) => address.main_text.startsWith(word)) || ( address.terms && address.terms.length < 5) ) {
      this.$root.$emit('bv::show::modal', 'alert-address-error')
    }
    if (modal) {
      isDrag = false
      this.isSubmit = false
      this.isSelectedModal = true
      if (
        this.inputLocationModal != address.main_text + ' ' + address.description &&
        !(this.center.lat == address.latitude && this.center.lng == address.longitude)
      ) {
        this.isFetching = true
        this.isFetching2 = true
      }
      if (!this.disabled) {
        this.inputLocationModal = address.main_text + ' ' + address.description
          try{
            this.center = { lat: address.latitude, lng: address.longitude }
            const newLatLng = new google.maps.LatLng(address.latitude, address.longitude)
            if (!maps) {
              this.initMaps()
            }
            marker && (marker.position = this.center)
            maps && maps.panTo(newLatLng)
            await this.getPlaceByLatLng(true)
          } catch (error) {
            console.log('select errror', error.message, this.center)

            setTimeout(async () => {
              const lat = address.latitude
              const lng = address.longitude
              this.center = { lat, lng }
              const newLatLng = new google.maps.LatLng(this.center)
              marker && (marker.position = this.center)
              maps && maps.panTo(newLatLng)
              await this.getPlaceByLatLng(true)
            }, 500)
            // this.$nextTick(() => {
            //   this.openModal()
            // })
          }
      }
      return
    }
    this.isFetching = true
    this.isFetching2 = true
    this.isSubmit = false
    isDrag = false
    this.$emit('input', { ...address, number: this.value.number })
    console.log('address', address)
    this.isTyping = false
    this.isSelectedNewAdd = true
    this.form.additional_details = null
    this.centerLocation = null

    if (!this.disabled) {
        try{
          this.position = position
          const lat = address.latitude
          const lng = address.longitude
          this.center = { lat, lng }
          const newLatLng = new google.maps.LatLng(this.center)
          if (!maps) {
            this.initMaps()
          }
          // console.log('lat', typeof this.center)
          marker && (marker.position = this.center)
          maps && maps.panTo(newLatLng)
          await this.getPlaceByLatLng()
          // this.openModal()
        } catch (error) {
          console.log('select errror', error.message, this.center)

          setTimeout(async () => {
            const lat = address.latitude
            const lng = address.longitude
            this.center = { lat, lng }
            const newLatLng = new google.maps.LatLng(this.center)
            marker && (marker.position = this.center)
            maps && maps.panTo(newLatLng)
            await this.getPlaceByLatLng()
          }, 750)
          // this.$nextTick(() => {
          //   this.openModal()
          // })
        }
    }
  }

  @Watch('value')
  onValueChanged(newVal, oldVal) {
    console.log(
      'addigtoinal',
      '\n',
      newVal.main_text,
      '\n',
      this.inputLocation,
      '\n',
      this.inputLocationModal
    )
    if (
      (newVal.main_text !== this.inputLocation || newVal.main_text !== this.inputLocationModal) &&
      newVal.main_text !== ''
    ) {
      console.log('newVal jaa', newVal, oldVal)
      this.isFetching = true
      this.inputLocation = newVal.main_text + ' ' + newVal.description
      this.inputLocationModal = newVal.main_text + ' ' + newVal.description

      this.text_main_location = newVal.main_text
      console.log('🚫 this.inputLocation this.text_main_location', this.text_main_location)
      console.log(
        '⭕️ this is defualt this.inputLocation ===>',
        this.inputLocation,
        this.inputLocationModal
      )
      console.log('🛑 this.inputLocation main_text ===> ', newVal.main_text)
      console.log('❌ this.inputLocation description ===> ', newVal.description)

      if (newVal.main_text.includes(newVal.description)) {
        this.inputLocationModal = newVal.main_text
        this.inputLocation = newVal.main_text
      }

      if (this.isDefaultLocation || this.isCenterDefault) {
        this.center = {
          lat: newVal.latitude,
          lng: newVal.longitude,
        }
      }
    }
    if (newVal.main_text === '') {
      this.inputLocation = ''
      this.inputLocationModal = ''
    }
    this.isFetching = false
    this.isFetching2 = false
  }

  // @Watch('inputLocation', 'inputLocationModal')
  // onTyped(newVal, oldVal) {
  //   this.isFetching = true
  //   this.isFetching2 = true

  //   this.$nextTick(() => {
  //     console.log(this.$refs.s_0) // returns undefined ???
  //   })

  //   this.timeoutInstance && clearTimeout(this.timeoutInstance)
  //   if (newVal !== oldVal) {
  //     this.timeoutInstance = setTimeout(() => {
  //       this.onInputLocationChanged
  //       this.isFetching = false
  //       this.isFetching2 = false
  //     }, 1000)
  //   }
  // }

  async onInputLocationChanged() {
    // console.log('sss', this.inputLocation)
    // this.$root.$emit('bv::show::modal', `gmap-picker-modal-${this.value.number}`)
    this.inputLocationModal = null
    if (!this.inputLocation && !this.isPreventQuery) {
      const defaultLocation = {
        description: '',
        main_text: '',
        place_id: '',
        secondary_text: '',
        latitude: '',
        longitude: '',
        number: this.value.number,
      }
      this.$emit('input', defaultLocation)
      return
    }
    // console.log('eeee : prevPage', this.prevPage)

    // this.isFetching = true
    // this.isFetching2 = true
    if(this.inputLocation.length < 3) return;
   clearTimeout(this.debounceAutocomplete)
   this.debounceAutocomplete = setTimeout(async () => {
    try {
      await this.fetchAvailableArea()
      const result = await ApiClient.mapsAutoComplete(this.inputLocation, this.$i18n.locale, this.temp_id, true)
      this.isFetching = false
      // this.isFetching2 = false
      if (result.data) {
        const places = result.data.map((place) => ({
          ...this.value,
          description: place.structured_formatting.secondary_text,
          main_text: place.structured_formatting.main_text,
          secondary_text: place.structured_formatting.secondary_text,
          place_id: place.place_id,
          terms: place.terms,
          latitude: 0,
          longitude: 0,
        }))
        // const locationWithLatLong = await Promise.all(
        //   places.map((place) => findLatLongIfNotExists(place))
        // )
        // this.searchResult = places.filter((place) => {
        //   return isPlaceInAvailableArea2(place, this.availableArea)
        // })
        this.searchResult = places

        console.log('test serach', this.searchResult)
        console.log('test availableArea', this.availableArea)
        this.$nextTick(() => {
          if (this.$refs.address_selector1) {
            this.$refs.address_selector1.scroll(0, 0)
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
   }, 750)
  }

  async onInputLocationChangedModal() {
    // console.log('sss', this.inputLocation)
    // this.$root.$emit('bv::show::modal', `gmap-picker-modal-${this.value.number}`)

    // if (!this.inputLocationModal && !this.isPreventQuery) {
    //   const defaultLocation = {
    //     description: '',
    //     main_text: '',
    //     place_id: '',
    //     secondary_text: '',
    //     latitude: '',
    //     longitude: '',
    //     number: this.value.number,
    //   }
    //   this.$emit('input', defaultLocation)
    //   return
    // }
    this.isFetching = true
    this.isFetching2 = true
    this.isSelectedModal = false
    if(this.inputLocationModal.length < 3) return;
   clearTimeout(this.debounceAutocomplete)
   this.debounceAutocomplete = setTimeout(async () => {
    try {
      await this.fetchAvailableArea()
      const result = await ApiClient.mapsAutoComplete(this.inputLocationModal, this.$i18n.locale, this.temp_id, true)
      this.isFetching2 = false
      if (result.data) {
        const places = result.data.map((place) => ({
          ...this.value,
          description: place.structured_formatting.secondary_text,
          main_text: place.structured_formatting.main_text,
          secondary_text: place.structured_formatting.secondary_text,
          place_id: place.place_id,
          terms: place.terms,
          latitude: 0,
          longitude: 0,
        }))
        // const locationWithLatLong = await Promise.all(
        //   places.map((place) => findLatLongIfNotExists(place))
        // )
        // this.searchResult = places.filter((place) => {
        //   return isPlaceInAvailableArea2(place, this.availableArea)
        // })
        this.searchResult = places
        this.$refs.address_selector2.scroll(0, 0)
      }
    } catch (error) {
      console.log(error)
    }
   }, 750)
  }

  async getCurrentLocation() {
    isDrag = false

    if (navigator.geolocation && !this.disabled) {
      isDrag = true
      // this.$root.$emit('bv::show::modal', `gmap-picker-modal-${this.value.number}`)
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { coords } = position
        this.center = { lat: coords.latitude, lng: coords.longitude }
        const newLatLng = new google.maps.LatLng(coords.latitude, coords.longitude)
        if (!maps) {
          this.initMaps()
        }
        maps && maps.panTo(newLatLng)
        await this.getPlaceByLatLng()
      }, (error) => {
        console.log('error', error.message)
      }, { enableHighAccuracy: true })
      // if user denied location
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        console.log(result.state);
        let setToDefaultCenter = false
        let timeoutDefaultCenter = null
        result.onchange = () => {
          if (result.state === 'denied') {
            console.log('geolocation denied 0')
            setToDefaultCenter = true
          }
        }
        if (result.state === 'denied') {
          console.log('geolocation denied 1')
          setToDefaultCenter = true
        }
        if (result.state === 'prompt') {
          console.log('geolocation prompt')
          setToDefaultCenter = true
        }
        if (setToDefaultCenter && this.isDefaultLocation) {
          clearTimeout(timeoutDefaultCenter)
          timeoutDefaultCenter = setTimeout(() => {
            this.center = this.centerDefault
            const newLatLng = new google.maps.LatLng(this.center)
            if (!maps) {
              this.initMaps()
            }
            maps && maps.panTo(newLatLng)
          }, 1000)
        }
      })
    }
  }

  initMaps() {
    var geocoder = new google.maps.Geocoder()
    if (!this.$refs.gmaps) {
      return
    }
    maps = new google.maps.Map(this.$refs.gmaps, {
      center: this.center,
      zoom: 15,
      mapId: 'AIzaSyDFUalmOzv8LkFm-RySIeJXU7lsQ2lKEzo', //localhost -> AIzaSyB2_jv7hL8PqWmPK9E72q0gH_fVtonwFkk
    })
    marker = new google.maps.marker.AdvancedMarkerElement({
      position: this.center,
      map: maps,
      title: 'Click to zoom',
      // draggable: true
    })
    isDrag = false

    google.maps.event.addListener(maps, 'dragend', function () {
      isDrag = true
    })

    google.maps.event.addListener(maps, 'zoom_changed', function () {
      isDrag = true
      console.log('zoom', isDrag)
    })
  }

  async getPlaceByLatLng(openModal = false) {
    if (!openModal) {
      this.isFetching = true
      this.isFetching2 = true
    }
    await this.fetchAvailableArea()
    try {
      const result = await ApiClient.getPlaceByLatLng(
        this.center.lat,
        this.center.lng,
        this.$i18n.locale
      )
      if (result.data.length > 0) {
        const place = result.data[0]
        console.log('place', result)
        // console.log('place match', this.form, place)
        if (!openModal && isDrag) {
          this.inputLocationModal = isDrag
            ? `${place.formatted_address}`
            : this.text_main_location == place.formatted_address
            ? `${this.text_main_location}`
            : this.text_main_location.includes(place.formatted_address)
            ? `${this.text_main_location}`
            : `${this.text_main_location} ${place.formatted_address}`
        }
        console.log('✅ this.inputLocation this.form.main_text ===>', this.inputLocation)

        this.form.description = ''
        this.form.secondary_text = ''
        this.form.latitude = place.geometry.location.lat
        this.form.longitude = place.geometry.location.lng
        this.form.place_id = place.place_id
        this.isSelectedModal = true

        this.areaLocation = {
          latitude: place.geometry.location.lat,
          longitude: place.geometry.location.lng
        }
        //             const request = {
        //   placeId: place.place_id,
        //   fields: ["name", "formatted_address", "place_id", "geometry"],
        // };
        // const service = new google.maps.places.PlacesService(maps);
        //            service.getDetails(request, (place, status) => {
        //             console.log("place",place)
        //             // this.placeNameFromGoogle = place.name
        //           })
      }

      // Validate area
      this.mapsPickerError = ''
      if (
        (!this.onFocus && this.isSubmit) ||
        (this.inputLocation && !this.isSelected && !this.multiPackage.isChangeMaid && this.isTyping)
      ) {
        this.mapsPickerError = ''
      }
      const currentLocation = this.areaLocation
      const isAvailableArea = isPlaceInAvailableArea2(
        currentLocation,
        this.availableArea
      )
      if (!isAvailableArea) {
        this.mapsPickerError = this.$t('error.this_area_is_not_available')
      }

      console.log('test2 availableArea', isAvailableArea)
    } catch (e) {
      console.log(e)
      console.log('Error getting location from lat/lng')
    }
    if (!openModal) {
      this.isFetching = false
      this.isFetching2 = false
    }
  }

  clearInputModalBooking() {
    this.inputLocation = ''
  }

  clearInputModal() {
    this.inputLocationModal = ''  
    }

  //Fuction open Searching Results and Modal
  onFocus() {
    this.isFocus = true
    this.isPreventQuery = false
    this.isTyping = true
  }

  offFocus() {
    setTimeout(() => this.isFocus = !this.isFocus, 500) 
  }

  onFocusDetails() {
    this.isPreventQuery = true
    this.isTyping = true
  }

  onModal() {
    this.openSelector = true
    this.isPreventQuery = false
    this.isTyping = true
  }

  offModal() {
    setTimeout(() => this.openSelector = !this.openSelector, 500) 
  }

  get isInputHasValue() {
    if (this.inputLocation === null && this.inputLocationModal === null) {
      return false
    }
    return true
  }
  //open Modal
  openModal() {
    // console.log('this.inputLocation in modal ❎', this.inputLocation)
    this.isFetching = true
    this.isFetching2 = true

    this.isSubmit = false
    this.isSelectedModal = true
    if (this.inputLocation === null || this.inputLocation === '') {
      this.inputLocationModal = this.inputLocation
      clearTimeout(this.mapsTimeout)
      this.mapsTimeout = setTimeout(async () => {
        await this.getCurrentLocation()
        this.isFetching = false
        this.isFetching2 = false
      }, 500)
    } else {
      console.log(this.center)
      if (
        (!this.onFocus && this.isSubmit) ||
        (this.inputLocation && !this.isSelected && !this.multiPackage.isChangeMaid && this.isTyping)
      ) {
        this.isSelectedModal = false
      }
      // this.onInputLocationChanged()
      // this.onInputLocationChangedModal()
      this.inputLocationModal = this.inputLocation
      this.mapsPickerError = ''
      if (this.centerLocation) {
        this.center = this.centerLocation
      } else if (!this.isDefaultLocation && !this.isCenterDefault) {
        this.centerLocation = this.center
      }
      if (!this.disabled) {
        console.log('onInputLocationChanged condition', this.center)
        clearTimeout(this.backUpTime)
        this.backUpTime = setTimeout(() => {
          this.isFetching = false
          this.isFetching2 = false
        }, 10000)

          const newLatLng = new google.maps.LatLng(this.center)
          if (!maps) {
            this.initMaps()
          }
          this.centerLocation = this.center
          marker && (marker.position = this.center)
          console.log('🌐 this.center ===>', this.center)
          maps && maps.panTo(newLatLng)
          clearTimeout(this.backUpTime)
          clearTimeout(this.mapsTimeout)
          this.mapsTimeout = setTimeout(async () => {
            // await this.getPlaceByLatLng(true)
            if (
              (!this.onFocus && this.isSubmit) ||
              (this.inputLocation &&
                !this.isSelected &&
                !this.multiPackage.isChangeMaid &&
                this.isTyping)
            ) {
              this.isSelectedModal = false
            }
            this.isFetching = false
            this.isFetching2 = false
          }, 500)

        this.isFetching = false
        this.isFetching2 = false
      } else {
        this.isFetching = false
        this.isFetching2 = false
      }
    }
    console.log('eeee : open modal', this.oldAddDetails, this.isSubmit, this.form)
    if (!this.modalFirstClick) {
      if (this.form.additional_details !== this.oldAddDetails && this.oldAddDetails) {
        this.form.additional_details = this.oldAddDetails
      }
      if (this.isSelectedNewAdd && this.isSubmit) {
        this.form.additional_details = null
      }
    }

    this.$root.$emit('bv::show::modal', `gmap-picker-modal-${this.value.number}`)
    this.modalFirstClick = false
  }

  onModalShown() {
    this.initMaps()
    // console.log('this.center', this.center)
    maps.addListener('center_changed', () => {
      console.log('center_changed')
      let tempCenter = { lat: this.center.lat, lng: this.center.lng }
      // console.log('center', this.center)
      // console.log('this.center_2', this.center)
      marker && (marker.position = maps.getCenter())
      clearTimeout(this.mapsTimeout)
      this.mapsTimeout = setTimeout(async () => {
        this.center = { lat: maps.getCenter().lat(), lng: maps.getCenter().lng() }
        if (
          ( Math.round(this.center.lat * 1000000) / 1000000 ===
            Math.round(this.centerDefault.lat * 1000000) / 1000000 &&
          Math.round(this.center.lng * 1000000) / 1000000 ===
            Math.round(this.centerDefault.lng * 1000000) / 1000000 ) ||
           ( this.center.lat == this.centerDefault.lat && this.center.lng == this.centerDefault.lng) ||
          this.checkOtherCenterDefault(this.center.lat, this.center.lng)
        ) {
          this.center = tempCenter
          const newLatLng = new google.maps.LatLng(this.center)
          marker && (marker.position = this.center)
          maps && maps.panTo(newLatLng)
          return
        }
        await this.getPlaceByLatLng()
      }, 500)
    })
  }

  onSubmit() {
    this.oldAddDetails = this.form.additional_details
    this.centerLocation = this.center
    console.log('❎❎❎❎❎ this.center submit', this.center)
    this.inputLocation =
      this.inputLocationModal.length > 0
        ? this.inputLocationModal.trimStart()
        : this.inputLocationModal
    this.recentInputLocation = this.inputLocationModal
    this.inputLocationModal = null
    this.form.main_text = this.inputLocation
    this.form.latitude = this.center.lat
    this.form.longitude = this.center.lng

    console.log('eeee : submit', this.form)
    this.$emit('input', { ...this.form, number: this.value.number })
    this.isSubmit = true
    this.$root.$emit('bv::hide::modal', `gmap-picker-modal-${this.value.number}`)
  }

  shownWarning() {
    if (this.inputLocation == null) {
      this.onFocus = true
    } else {
      this.onFocus = false
    }
  }

  get inputStyle() {
    return this.disabled
      ? `cursor:not-allowed;pointer-events: none;background:#f8f9fa;opacity:0.65;border:#dddfe;`
      : `cursor:pointer;background:#fff;`
  }

  get inputDisabledLocation() {
    return this.disabledLocation
      ? `cursor:not-allowed;pointer-events: none;background:#f8f9fa;opacity:0.65;border:#dddfe;`
      : `cursor:pointer;background:#fff;`
  }

  get label() {
    return this.isMyAccount ? `` : this.$t('form_label.location')
  }

  get isApproved() {
    if (!this.book_raw) return false
    return this.book_raw.booking.booking_status === STATUS.approved
  }
}
</script>

<style scoped>
.gm-svpc {
  display: none;
}

fieldset#additional_required {
  margin-bottom: 5px;
}

.address-location .input-group-text#search {
  width: 48px;
  border-right: none;
  background-color: #ffffff;
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.address-selector .form-control#beforemodal {
  margin-right: 0.25rem;
  border: 1px solid #dddfe7;
}
input#beforemodal {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
input#locationinput {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.clear:active {
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.clear:hover {
  border: none;
  background-color: transparent;
  box-shadow: none;
}
button.btn.clear.btn-secondary {
  background-color: transparent;
  box-shadow: none;
}
button.btn.clear.btn-secondary:active {
  background-color: transparent;
  box-shadow: none;
}
button.btn.clear.btn-secondary:focus {
  background-color: transparent;
  box-shadow: none;
  border: none;
}
button.btn.clear.btn-secondary:active:focus {
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.clear {
  border: none;
  background-color: transparent;
}
.clear-text {
  position: absolute;
  /* margin-top: 12px; */
  right: 55px;
}
.address-selector-container {
  width: 100%;
  max-height: 50vh;
  margin-right: 0.5rem;
}
.modal .address-selector-container {
  width: 90%;
  /* margin-right: 0.5rem; */
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  #map {
    height: 300px !important;
  }
  .address-selector-container {
    max-height: 50vh !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .modal .address-selector-container {
    width: 85%;
  }
}

@media screen and (min-width: 375px) and (max-width: 480px) {
  .modal .address-selector-container {
    width: 90%;
  }
}

.loading-dots {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 30;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
}

.location-current {
  color: #757575;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
.clear-input-area-button {
    color: #b9b9b9;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    font-weight: lighter;
    font-size: 14px;
    right: 48px;
    bottom: 5px;
}

.clear-input-area-button-1 {
    color: #b9b9b9;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    font-weight: lighter;
    font-size: 14px;
    right: 5px;
    bottom: 5px;
}
</style>
